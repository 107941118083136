import { yupResolver } from "@hookform/resolvers/yup";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  ThemeProvider,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import SnackbarContext from "../../contexts/SnackbarContextProvider";
import { loginSchema } from "../../schemas/loginSchema";
import { login } from "../../services/authentication/login";
import { lightTheme } from "../../themes/lightTheme";
import cookie from "../../utils/cookie";
import { generateSnackbarErrorMessage } from "../../utils/generateSnackbarErrorMessage";
import "./Login.scss";

const Login = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      company_code: "",
      password: "",
      username: "",
    },
    resolver: yupResolver(loginSchema),
  });
  const [isLoadingButtonSubmit, setIsLoadingButtonSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const onSubmitLogin = async (data) => {
    try {
      setIsLoadingButtonSubmit(true);

      data.company_code = data.company_code.toLowerCase();

      const res = await login(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        cookie.setCookie(
          "is_logged_in",
          true,
          `${process.env.REACT_APP_COOKIE_EXPIRES_IN}`
        );
        localStorage.setItem("company_code", res.payload.company_code);
        localStorage.setItem("user_id", res.payload.id);
        localStorage.setItem("user_username", res.payload.username);
        localStorage.setItem(
          "user_profile_picture_name",
          res.payload.profile_picture_name
        );
        localStorage.setItem("user_role_id", res.payload.role_id);
        localStorage.setItem("user_role_name", res.payload.role_name);
        localStorage.setItem("user_role_json", res.payload.role_json);
        navigate("/admin");
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonSubmit(false);
    }
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <div className="login__container">
        <div className="login__box login__box--left">
          <div className="login__header">
            <img alt="" src={logo} />
            <h1>Codeflair</h1>
          </div>
          <div className="login__content">
            <h2>Welcome Back!</h2>
            <p>
              Log in to get in the moment updates on the things that interest
              you
            </p>
            <form onSubmit={handleSubmit(onSubmitLogin)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="company_code"
                    render={({
                      field: { onChange, ref, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        error={!!error}
                        fullWidth
                        helperText={error?.message}
                        inputRef={ref}
                        label="Company Code"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="username"
                    render={({
                      field: { onChange, ref, value },
                      fieldState: { error },
                    }) => (
                      <TextField
                        error={!!error}
                        fullWidth
                        helperText={error?.message}
                        inputRef={ref}
                        label="Username"
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="password"
                    render={({
                      field: { onChange, ref, value },
                      fieldState: { error },
                    }) => (
                      <FormControl fullWidth>
                        <InputLabel error={!!error}>Password</InputLabel>
                        <OutlinedInput
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() =>
                                  setIsShowPassword(!isShowPassword)
                                }
                                edge="end"
                              >
                                {isShowPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          error={!!error}
                          inputRef={ref}
                          label="Password"
                          onChange={onChange}
                          type={isShowPassword ? "text" : "password"}
                          value={value}
                        />
                        <FormHelperText error>{error?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction={{ sm: "row", xs: "column" }}
                    justifyContent="flex-end"
                  >
                    <LoadingButton
                      endIcon={<LoginIcon />}
                      fullWidth
                      loading={isLoadingButtonSubmit}
                      loadingPosition="end"
                      type="submit"
                    >
                      Login
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </div>
          <div className="login__footer">
            <p>&copy;2024. Codeflair. All rights reserved</p>
            <a href="#">Terms & condition</a>
            <a href="#">Privacy & Policy</a>
          </div>
        </div>
        <div className="login__box login__box--right">
          <img alt="" src={logo} />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Login;
