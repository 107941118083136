import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Forbidden from "../components/Forbidden";
import NotFound from "../components/NotFound";
import { modules } from "../constants/modules";
import AdminLayout from "../layouts/admin/AdminLayout";
import { AdminMiddleware } from "../middlewares/AdminMiddleware";
import { LoginMiddleware } from "../middlewares/LoginMiddleware";
import RoleMiddleware from "../middlewares/RoleMiddleware";
import Login from "../pages/Login";
import AttendanceCalculation from "../pages/admin/AttendanceCalculation";
import AttendanceReport from "../pages/admin/AttendanceReport";
import AttendanceSummary from "../pages/admin/AttendanceSummary";
import BpjsCalculation from "../pages/admin/BpjsCalculation";
import BpjsKesehatanReport from "../pages/admin/BpjsKesehatanReport";
import BpjsKetenagakerjaanReport from "../pages/admin/BpjsKetenagakerjaanReport";
import BpjsMembership from "../pages/admin/BpjsMembership";
import CompanyAttendance from "../pages/admin/CompanyAttendance";
import CompanyOrganization from "../pages/admin/CompanyOrganization";
import CompanyWage from "../pages/admin/CompanyWage";
import CountryBpjsData from "../pages/admin/CountryBpjsData";
import CountryMinimumWage from "../pages/admin/CountryMinimumWage";
import CountryTax from "../pages/admin/CountryTax";
import Dashboard from "../pages/admin/Dashboard";
import Debt from "../pages/admin/Debt";
import DebtReport from "../pages/admin/DebtReport";
import DepartmentWageReport from "../pages/admin/DepartmentWageReport";
import EmployeeEmployment from "../pages/admin/EmployeeEmployment";
import EmployeePersonalData from "../pages/admin/EmployeePersonalData";
import EmployeeWageReport from "../pages/admin/EmployeeWageReport";
import ManageCompany from "../pages/admin/ManageCompany";
import ManageJkkContribution from "../pages/admin/ManageJkkContribution";
import ManageMinimumWage from "../pages/admin/ManageMinimumWage";
import ManagePeriod from "../pages/admin/ManagePeriod";
import ManageProfile from "../pages/admin/ManageProfile";
import ManageUser from "../pages/admin/ManageUser";
import ManageUserDetail from "../pages/admin/ManageUser/ManageUserDetail";
import ManageUserRole from "../pages/admin/ManageUserRole";
import ManageUserRoleDetail from "../pages/admin/ManageUserRole/ManageUserRoleDetail";
import ManageWage from "../pages/admin/ManageWage";
import ManualAttendance from "../pages/admin/ManualAttendance";
import Overtime from "../pages/admin/Overtime";
import PaymentOrderReport from "../pages/admin/PaymentOrderReport";
import Pph21CalculationProgressive from "../pages/admin/Pph21CalculationProgressive";
import Pph21CalculationTer from "../pages/admin/Pph21CalculationTer";
import Pph21Report from "../pages/admin/Pph21Report";
import SalaryCalculation from "../pages/admin/SalaryCalculation";
import SalaryCorrection from "../pages/admin/SalaryCorrection";
import SalarySlipReport from "../pages/admin/SalarySlipReport";
import TaxSummary from "../pages/admin/TaxSummary";
import TransactionProofReport from "../pages/admin/TransactionProofReport";
import WageOnHoldReport from "../pages/admin/WageOnHoldReport";
import { getLandingPage } from "../utils/getLandingPage";

const MyRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    return () => {};
  }, [location.pathname]);

  return (
    <Routes>
      <Route element={<LoginMiddleware />}>
        <Route element={<Login />} path="/" />
        <Route element={<Login />} path="/login" />
      </Route>
      <Route element={<AdminMiddleware />}>
        <Route path="/admin" element={<AdminLayout />}>
          <Route element={<Navigate to={getLandingPage()} />} path="" />
          <Route element={<RoleMiddleware />}>
            <Route
              element={<AttendanceCalculation />}
              path={modules.ATTENDANCE_CALCULATION.key.toLowerCase()}
            />
            <Route
              element={<AttendanceReport />}
              path={modules.ATTENDANCE_REPORT.key.toLowerCase()}
            />
            <Route
              element={<AttendanceSummary />}
              path={modules.ATTENDANCE_SUMMARY.key.toLowerCase()}
            />
            <Route
              element={<BpjsCalculation />}
              path={modules.BPJS_CALCULATION.key.toLowerCase()}
            />
            <Route
              element={<BpjsKesehatanReport />}
              path={modules.BPJS_KESEHATAN_REPORT.key.toLowerCase()}
            />
            <Route
              element={<BpjsKetenagakerjaanReport />}
              path={modules.BPJS_KETENAGAKERJAAN_REPORT.key.toLowerCase()}
            />
            <Route element={<BpjsMembership />} path="bpjs_membership" />
            <Route element={<CompanyAttendance />} path="company_attendance" />
            <Route
              element={<CompanyOrganization />}
              path="company_organization"
            />
            <Route element={<CompanyWage />} path="company_wage" />
            <Route element={<CountryBpjsData />} path="country_bpjs" />
            <Route
              element={<CountryMinimumWage />}
              path="country_minimum_wage"
            />
            <Route element={<CountryTax />} path="country_tax" />
            <Route
              element={<Dashboard />}
              path={modules.DASHBOARD.key.toLowerCase()}
            />
            <Route element={<Debt />} path={modules.DEBT.key.toLowerCase()} />
            <Route
              element={<DebtReport />}
              path={modules.DEBT_REPORT.key.toLowerCase()}
            />
            <Route
              element={<DepartmentWageReport />}
              path={modules.DEPARTMENT_WAGE_REPORT.key.toLowerCase()}
            />
            <Route
              element={<EmployeeEmployment />}
              path="employee_employment"
            />
            <Route
              element={<EmployeePersonalData />}
              path="employee_personal_data"
            />
            <Route
              element={<EmployeeWageReport />}
              path={modules.EMPLOYEE_WAGE_REPORT.key.toLowerCase()}
            />
            <Route
              element={<ManageCompany />}
              path={modules.MANAGE_COMPANY.key.toLowerCase()}
            />
            <Route
              element={<ManageJkkContribution />}
              path={modules.MANAGE_JKK_CONTRIBUTION.key.toLowerCase()}
            />
            <Route
              element={<ManageMinimumWage />}
              path={modules.MANAGE_MINIMUM_WAGE.key.toLowerCase()}
            />
            <Route
              element={<ManagePeriod />}
              path={modules.MANAGE_PERIOD.key.toLowerCase()}
            />
            <Route
              element={<ManageProfile />}
              path={modules.MANAGE_PROFILE.key.toLowerCase()}
            />
            <Route element={<ManageUser />} path={modules.MANAGE_USER.key} />
            <Route
              element={<ManageUserDetail />}
              path={`${modules.MANAGE_USER.key}/:id`}
            />
            <Route
              element={<ManageUserRole />}
              path={modules.MANAGE_USER_ROLE.key}
            />
            <Route
              element={<ManageUserRoleDetail />}
              path={`${modules.MANAGE_USER_ROLE.key}/:id`}
            />
            <Route
              element={<ManageWage />}
              path={modules.MANAGE_WAGE.key.toLowerCase()}
            />
            <Route element={<ManualAttendance />} path="manual_attendance" />
            <Route
              element={<Overtime />}
              path={modules.OVERTIME.key.toLowerCase()}
            />
            <Route
              element={<PaymentOrderReport />}
              path={modules.PAYMENT_ORDER_REPORT.key.toLowerCase()}
            />
            <Route
              element={<Pph21CalculationProgressive />}
              path={modules.PPH21_CALCULATION_PROGRESSIVE.key.toLowerCase()}
            />
            <Route
              element={<Pph21CalculationTer />}
              path={modules.PPH21_CALCULATION_TER.key.toLowerCase()}
            />
            <Route
              element={<Pph21Report />}
              path={modules.PPH21_REPORT.key.toLowerCase()}
            />
            <Route
              element={<SalaryCalculation />}
              path={modules.SALARY_CALCULATION.key.toLowerCase()}
            />
            <Route
              element={<SalaryCorrection />}
              path={modules.SALARY_CORRECTION.key.toLowerCase()}
            />
            <Route
              element={<SalarySlipReport />}
              path={modules.SALARY_SLIP_REPORT.key.toLowerCase()}
            />
            <Route
              element={<TaxSummary />}
              path={modules.TAX_SUMMARY.key.toLowerCase()}
            />
            <Route
              element={<TransactionProofReport />}
              path={modules.TRANSACTION_PROOF_REPORT.key.toLowerCase()}
            />
            <Route
              element={<WageOnHoldReport />}
              path={modules.WAGE_ON_HOLD_REPORT.key.toLowerCase()}
            />
          </Route>
          <Route element={<NotFound />} path="*" />
          <Route element={<Forbidden />} path="403" />
        </Route>
      </Route>
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
};

export default MyRoutes;
