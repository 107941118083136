import { modules } from "./modules";

export const landingPageOrder = {
  DASHBOARD: { key: modules.DASHBOARD.key, url: "dashboard" },
  COUNTRY_MINIMUM_WAGE: {
    key: modules.COUNTRY_MINIMUM_WAGE.key,
    url: "country_minimum_wage",
  },
  COUNTRY_BPJS: { key: modules.COUNTRY_BPJS.key, url: "country_bpjs" },
  JKK_CONTRIBUTION: { key: modules.JKK_CONTRIBUTION.key, url: "country_bpjs" },
  PTKP_TER: { key: modules.PTKP_TER.key, url: "country_tax" },
  PTKP_PROGRESSIVE: { key: modules.PTKP_PROGRESSIVE.key, url: "country_tax" },
  PPH21_TER: { key: modules.PPH21_TER.key, url: "country_tax" },
  PPH21_PROGRESSIVE: { key: modules.PPH21_PROGRESSIVE.key, url: "country_tax" },
  BUSINESS_GROUP: {
    key: modules.BUSINESS_GROUP.key,
    url: "company_organization",
  },
  DEPARTMENT: { key: modules.DEPARTMENT.key, url: "company_organization" },
  GRADE: { key: modules.GRADE.key, url: "company_organization" },
  WORKING_DAY: { key: modules.WORKING_DAY.key, url: "company_organization" },
  WAGE_STRUCTURE_GRADE: {
    key: modules.WAGE_STRUCTURE_GRADE.key,
    url: "company_wage",
  },
  WAGE_STRUCTURE_YEARS_OF_SERVICE: {
    key: modules.WAGE_STRUCTURE_YEARS_OF_SERVICE.key,
    url: "company_wage",
  },
  HOLIDAY: { key: modules.HOLIDAY.key, url: "company_attendance" },
  PERIOD: { key: modules.PERIOD.key, url: "company_attendance" },
  WORK_SCHEDULE: { key: modules.WORK_SCHEDULE.key, url: "company_attendance" },
  IDENTITY_CARD: {
    key: modules.IDENTITY_CARD.key,
    url: "employee_personal_data",
  },
  FAMILY_CARD: { key: modules.FAMILY_CARD.key, url: "employee_personal_data" },
  BANK: { key: modules.BANK.key, url: "employee_personal_data" },
  BPJS_KESEHATAN: {
    key: modules.BPJS_KESEHATAN.key,
    url: "employee_personal_data",
  },
  BPJS_KETENAGAKERJAAN: {
    key: modules.BPJS_KETENAGAKERJAAN.key,
    url: "employee_personal_data",
  },
  TAX_DATA: { key: modules.TAX_DATA.key, url: "employee_personal_data" },
  WORK_AGREEMENT: {
    key: modules.WORK_AGREEMENT.key,
    url: "employee_employment",
  },
  WAGE_AGREEMENT: {
    key: modules.WAGE_AGREEMENT.key,
    url: "employee_employment",
  },
  BPJS_MEMBERSHIP_FAMILY: {
    key: modules.BPJS_MEMBERSHIP_FAMILY.key,
    url: "bpjs_membership",
  },
  BPJS_MEMBERSHIP_EMPLOYEE: {
    key: modules.BPJS_MEMBERSHIP_EMPLOYEE.key,
    url: "bpjs_membership",
  },
  DEBT: {
    key: modules.DEBT.key,
    url: modules.DEBT.key.toLowerCase(),
  },
  MANUAL_ATTENDANCE_WEEK_1: {
    key: modules.MANUAL_ATTENDANCE_WEEK_1.key,
    url: "manual_attendance",
  },
  MANUAL_ATTENDANCE_WEEK_2: {
    key: modules.MANUAL_ATTENDANCE_WEEK_2.key,
    url: "manual_attendance",
  },
  MANUAL_ATTENDANCE_WEEK_3: {
    key: modules.MANUAL_ATTENDANCE_WEEK_3.key,
    url: "manual_attendance",
  },
  MANUAL_ATTENDANCE_WEEK_4: {
    key: modules.MANUAL_ATTENDANCE_WEEK_4.key,
    url: "manual_attendance",
  },
  OVERTIME: {
    key: modules.OVERTIME.key,
    url: modules.OVERTIME.key.toLowerCase(),
  },
  ATTENDANCE_SUMMARY: {
    key: modules.ATTENDANCE_SUMMARY.key,
    url: modules.ATTENDANCE_SUMMARY.key.toLowerCase(),
  },
  TAX_SUMMARY: {
    key: modules.TAX_SUMMARY.key,
    url: modules.TAX_SUMMARY.key.toLowerCase(),
  },
  BPJS_CALCULATION: {
    key: modules.BPJS_CALCULATION.key,
    url: modules.BPJS_CALCULATION.key.toLowerCase(),
  },
  ATTENDANCE_CALCULATION: {
    key: modules.ATTENDANCE_CALCULATION.key,
    url: modules.ATTENDANCE_CALCULATION.key.toLowerCase(),
  },
  SALARY_CALCULATION: {
    key: modules.SALARY_CALCULATION.key,
    url: modules.SALARY_CALCULATION.key.toLowerCase(),
  },
  PPH21_CALCULATION_TER: {
    key: modules.PPH21_CALCULATION_TER.key,
    url: modules.PPH21_CALCULATION_TER.key.toLowerCase(),
  },
  PPH21_CALCULATION_PROGRESSIVE: {
    key: modules.PPH21_CALCULATION_PROGRESSIVE.key,
    url: modules.PPH21_CALCULATION_PROGRESSIVE.key.toLowerCase(),
  },
  BPJS_KESEHATAN_REPORT: {
    key: modules.BPJS_KESEHATAN_REPORT.key,
    url: modules.BPJS_KESEHATAN_REPORT.key.toLowerCase(),
  },
  BPJS_KETENAGAKERJAAN_REPORT: {
    key: modules.BPJS_KETENAGAKERJAAN_REPORT.key,
    url: modules.BPJS_KETENAGAKERJAAN_REPORT.key.toLowerCase(),
  },
  ATTENDANCE_REPORT: {
    key: modules.ATTENDANCE_REPORT.key,
    url: modules.ATTENDANCE_REPORT.key.toLowerCase(),
  },
  WAGE_ON_HOLD_REPORT: {
    key: modules.WAGE_ON_HOLD_REPORT.key,
    url: modules.WAGE_ON_HOLD_REPORT.key.toLowerCase(),
  },
  DEBT_REPORT: {
    key: modules.DEBT_REPORT.key,
    url: modules.DEBT_REPORT.key.toLowerCase(),
  },
  PPH21_REPORT: {
    key: modules.PPH21_REPORT.key,
    url: modules.PPH21_REPORT.key.toLowerCase(),
  },
  EMPLOYEE_WAGE_REPORT: {
    key: modules.EMPLOYEE_WAGE_REPORT.key,
    url: modules.EMPLOYEE_WAGE_REPORT.key.toLowerCase(),
  },
  DEPARTMENT_WAGE_REPORT: {
    key: modules.DEPARTMENT_WAGE_REPORT.key,
    url: modules.DEPARTMENT_WAGE_REPORT.key.toLowerCase(),
  },
  PAYMENT_ORDER_REPORT: {
    key: modules.PAYMENT_ORDER_REPORT.key,
    url: modules.PAYMENT_ORDER_REPORT.key.toLowerCase(),
  },
  TRANSACTION_PROOF_REPORT: {
    key: modules.TRANSACTION_PROOF_REPORT.key,
    url: modules.TRANSACTION_PROOF_REPORT.key.toLowerCase(),
  },
  SALARY_SLIP_REPORT: {
    key: modules.SALARY_SLIP_REPORT.key,
    url: modules.SALARY_SLIP_REPORT.key.toLowerCase(),
  },
  MANAGE_COMPANY: {
    key: modules.MANAGE_COMPANY.key,
    url: modules.MANAGE_COMPANY.key.toLowerCase(),
  },
  MANAGE_USER: {
    key: modules.MANAGE_USER.key,
    url: modules.MANAGE_USER.key.toLowerCase(),
  },
  MANAGE_USER_ROLE: {
    key: modules.MANAGE_USER_ROLE.key,
    url: modules.MANAGE_USER_ROLE.key.toLowerCase(),
  },
  MANAGE_PERIOD: {
    key: modules.MANAGE_PERIOD.key,
    url: modules.MANAGE_PERIOD.key.toLowerCase(),
  },
  MANAGE_JKK_CONTRIBUTION: {
    key: modules.MANAGE_JKK_CONTRIBUTION.key,
    url: modules.MANAGE_JKK_CONTRIBUTION.key.toLowerCase(),
  },
  MANAGE_MINIMUM_WAGE: {
    key: modules.MANAGE_MINIMUM_WAGE.key,
    url: modules.MANAGE_MINIMUM_WAGE.key.toLowerCase(),
  },
  MANAGE_WAGE: {
    key: modules.MANAGE_WAGE.key,
    url: modules.MANAGE_WAGE.key.toLowerCase(),
  },
};
