import { postMethodToken } from "../../utils/httpMethod/postMethodToken";

export const exportBpjsMembershipEmployeeData = async (data, config) => {
  const res = await postMethodToken(
    "admin/bpjs_membership_employee/export_data",
    data,
    config
  );
  return res;
};
