export const modules = {
  ATTENDANCE_CALCULATION: {
    key: "ATTENDANCE_CALCULATION",
    value: "Perhitungan Absensi",
  },
  ATTENDANCE_REPORT: { key: "ATTENDANCE_REPORT", value: "Absensi" },
  ATTENDANCE_SUMMARY: { key: "ATTENDANCE_SUMMARY", value: "Rekap Absensi" },
  BANK: { key: "BANK", value: "Bank" },
  BPJS_CALCULATION: { key: "BPJS_CALCULATION", value: "BPJS" },
  BPJS_KESEHATAN: { key: "BPJS_KESEHATAN", value: "BPJS Kesehatan" },
  BPJS_KETENAGAKERJAAN: {
    key: "BPJS_KETENAGAKERJAAN",
    value: "BPJS Ketenagakerjaan",
  },
  BPJS_KESEHATAN_REPORT: {
    key: "BPJS_KESEHATAN_REPORT",
    value: "BPJS Kesehatan",
  },
  BPJS_KETENAGAKERJAAN_REPORT: {
    key: "BPJS_KETENAGAKERJAAN_REPORT",
    value: "BPJS Ketenagakerjaan",
  },
  BPJS_MEMBERSHIP_EMPLOYEE: {
    key: "BPJS_MEMBERSHIP_EMPLOYEE",
    value: "Pegawai",
  },
  BPJS_MEMBERSHIP_FAMILY: {
    key: "BPJS_MEMBERSHIP_FAMILY",
    value: "Anggota Keluarga",
  },
  BUSINESS_GROUP: { key: "BUSINESS_GROUP", value: "Kelompok Usaha" },
  COUNTRY_BPJS: { key: "COUNTRY_BPJS", value: "BPJS Negara" },
  COUNTRY_MINIMUM_WAGE: { key: "COUNTRY_MINIMUM_WAGE", value: "UMK" },
  DASHBOARD: { key: "DASHBOARD", value: "Dashboard" },
  DEBT: { key: "DEBT", value: "Hutang" },
  DEBT_REPORT: { key: "DEBT_REPORT", value: "Hutang" },
  DEPARTMENT: { key: "DEPARTMENT", value: "Departemen" },
  DEPARTMENT_WAGE_REPORT: {
    key: "DEPARTMENT_WAGE_REPORT",
    value: "Rincinan Per Gaji Departemen",
  },
  EMPLOYEE_WAGE_REPORT: {
    key: "EMPLOYEE_WAGE_REPORT",
    value: "Rincinan Gaji Pegawai",
  },
  FAMILY_CARD: { key: "FAMILY_CARD", value: "KK" },
  GRADE: { key: "GRADE", value: "Jabatan & Grade" },
  HOLIDAY: { key: "HOLIDAY", value: "Tanggal Merah" },
  IDENTITY_CARD: { key: "IDENTITY_CARD", value: "KTP" },
  JKK_CONTRIBUTION: { key: "JKK_CONTRIBUTION", value: "Iuran JKK" },
  MANAGE_COMPANY: { key: "MANAGE_COMPANY", value: "Perusahaan" },
  MANAGE_JKK_CONTRIBUTION: {
    key: "MANAGE_JKK_CONTRIBUTION",
    value: "Klasifikasi JKK",
  },
  MANAGE_MINIMUM_WAGE: {
    key: "MANAGE_MINIMUM_WAGE",
    value: "UMK",
  },
  MANAGE_PERIOD: {
    key: "MANAGE_PERIOD",
    value: "Periode",
  },
  MANAGE_PROFILE: {
    key: "MANAGE_PROFILE",
    value: "Ubah Profil",
  },
  MANAGE_USER: { key: "MANAGE_USER", value: "Pengguna" },
  MANAGE_USER_ROLE: { key: "MANAGE_USER_ROLE", value: "Peran" },
  MANAGE_WAGE: { key: "MANAGE_WAGE", value: "Komponen Gaji" },
  MANUAL_ATTENDANCE_WEEK_1: {
    key: "MANUAL_ATTENDANCE_WEEK_1",
    value: "Minggu 1",
  },
  MANUAL_ATTENDANCE_WEEK_2: {
    key: "MANUAL_ATTENDANCE_WEEK_2",
    value: "Minggu 2",
  },
  MANUAL_ATTENDANCE_WEEK_3: {
    key: "MANUAL_ATTENDANCE_WEEK_3",
    value: "Minggu 3",
  },
  MANUAL_ATTENDANCE_WEEK_4: {
    key: "MANUAL_ATTENDANCE_WEEK_4",
    value: "Minggu 4",
  },
  OVERTIME: {
    key: "OVERTIME",
    value: "Lembur",
  },
  PAYMENT_ORDER_REPORT: {
    key: "PAYMENT_ORDER_REPORT",
    value: "Surat Perintah Bayar",
  },
  PERIOD: { key: "PERIOD", value: "Periode Payroll" },
  PPH21_CALCULATION_TER: {
    key: "PPH21_CALCULATION_TER",
    value: "Perhitungan PPh 21 Ter",
  },
  PPH21_CALCULATION_PROGRESSIVE: {
    key: "PPH21_CALCULATION_PROGRESSIVE",
    value: "Perhitungan PPh 21 Progresiv",
  },
  PPH21_REPORT: { key: "PPH21_REPORT", value: "PPh 21" },
  PPH21_PROGRESSIVE: { key: "PPH21_PROGRESSIVE", value: "PPh 21 Progresif" },
  PPH21_TER: { key: "PPH21_TER", value: "PPh 21 Ter" },
  PTKP_PROGRESSIVE: { key: "PTKP_PROGRESSIVE", value: "PTKP Progresif" },
  PTKP_TER: { key: "PTKP_TER", value: "PTKP Ter" },
  SALARY_CALCULATION: { key: "SALARY_CALCULATION", value: "Perhitungan Gaji" },
  SALARY_CORRECTION: {
    key: "SALARY_CORRECTION",
    value: "Koreksi Gaji",
  },
  SALARY_SLIP_REPORT: { key: "SALARY_SLIP_REPORT", value: "Slip Gaji" },
  TAX_DATA: { key: "TAX_DATA", value: "Data Pajak (NPWP & PTKP)" },
  TAX_SUMMARY: { key: "TAX_SUMMARY", value: "Rekap Pajak" },
  TRANSACTION_PROOF_REPORT: {
    key: "TRANSACTION_PROOF_REPORT",
    value: "Bukti Transaksi",
  },
  WAGE_AGREEMENT: { key: "WAGE_AGREEMENT", value: "Kesepakatan Gaji" },
  WAGE_ON_HOLD_REPORT: {
    key: "WAGE_ON_HOLD_REPORT",
    value: "Hold Gaji",
  },
  WAGE_STRUCTURE_GRADE: {
    key: "WAGE_STRUCTURE_GRADE",
    value: "Berdasarkan Grade",
  },
  WAGE_STRUCTURE_YEARS_OF_SERVICE: {
    key: "WAGE_STRUCTURE_YEARS_OF_SERVICE",
    value: "Berdasarkan Masa Kerja",
  },
  WORKING_DAY: { key: "WORKING_DAY", value: "Hari Kerja" },
  WORK_AGREEMENT: { key: "WORK_AGREEMENT", value: "Kesepakatan Kerja" },
  WORK_SCHEDULE: { key: "WORK_SCHEDULE", value: "Jadwal Kerja" },
};
